import React from "react"
import Layout from "../components/note-layout"
import { Link } from "gatsby"
import "../components/portfolio.css"

export default function Work() {
  return (
    <Layout>
    <div style={{margin: 10 + 'vw'}}>
      <h1>Uh oh!</h1>
      <p className="page-para">
        Sorry, looks like we lost our train of thought. The page you're looking for doesn't seem to exist.
        </p>
    </div>
    </Layout>
  )
}
